@import 'styles/utils';

.wrapper {
  transform: translateY(-100px);
  position: relative;
  z-index: 100;
  margin-bottom: -50px;
  @include breakpoint('md') {
    margin-bottom: initial;
  }
  @include breakpoint('lg') {
    transform: translateY(-125px);
  }
}

.gridContainer {
  width: 100%;
  padding: 0 3%;
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7));
  border-radius: 4px;
  z-index: 1;
}

.imageBig {
  width: 100%;
  height: 100%;
  max-height: 620px;
  display: block;
  border-radius: 4px;
  object-fit: cover;
  z-index: 0;
}

.bigGridItemContentWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 1;
  @include breakpoint('md') {
    padding: 40px;
  }
  @include breakpoint('xxl') {
    padding: 40px 75px 40px 40px;
  }
}

.subtitle {
  color: $white;
  display: block;
  padding-bottom: 2px;
  line-height: '24px';
}

.title {
  color: $white;
  display: block;
  line-height: 32px;
}

.buttonWrapper {
  display: none;
  align-items: center;
  padding-top: 20px;
  @include breakpoint('md') {
    display: inline-flex;
  }
}

.imageSmall {
  width: 100%;
  height: 200px;
  display: block;
  object-fit: cover;
  border-radius: 4px;
  z-index: 0;
  @include breakpoint('md') {
    height: 300px;
  }
}

.smallGridItemContentWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 1;
}

.linkWrapper {
  display: inline-flex;
  align-items: center;
  padding-top: 5px;
}

.linkWrapperSlider {
  display: inline-flex;
  align-items: center;
  padding-top: 5px;
  @include breakpoint('md') {
    display: none;
  }
}

.link {
  color: inherit;
  text-decoration: none;
  display: block;
  align-items: center;
  cursor: pointer;
}

.linkText {
  color: $starcar-yellow;
  display: block;
  margin-right: 4px;
}

.icon {
  height: 24px;
  width: 24px;
  color: $starcar-yellow;
}

.imageLink:hover .linkWrapper {
  transform: translateX(10px);
  transition: transform 0.3s ease;
}

.link:hover .linkWrapper {
  transform: translateX(10px);
  transition: transform 0.3s ease;
}

.carouselWrapper.carouselWrapper {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  height: 100%;
}
.imageBox {
  width: 100%;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
}

.imageContainer {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 4px;
  display: block;
  @include breakpoint('md') {
    height: 100%;
  }
}

.imageWrapper {
  display: flex;
}
